import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, url }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          {url ? (
            <h4>
              <a href={url} target="_blank" rel="noopener noreferrer" className="proj-title-link">
                {title}
              </a>
            </h4>
          ) : (
            <h4>{title}</h4>
          )}
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};
