import python from "../assets/img/python.svg";
import github from "../assets/img/github.svg";
import raspberrypi from "../assets/img/raspberrypi.svg";
import selenium from "../assets/img/selenium.svg";
import figma from "../assets/img/figma.svg";
import arduino from "../assets/img/arduino.svg";
import flutter from "../assets/img/flutter.svg";
import react from '../assets/img/react.svg';
import tensorflow from '../assets/img/tensorflow.svg';
import cpp from '../assets/img/cpp.svg';
import Carousel from 'react-multi-carousel';
import android from '../assets/img/android.svg';
import 'react-multi-carousel/lib/styles.css';


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>I work with a diverse set of technologies, including Selenium for web automation, Python for scripting, and C++/C for system-level programming. My projects span IoT with Arduino/Raspberry Pi, web development with HTML5, CSS3, JavaScript, React JS, and Flutter, and AI projects using TensorFlow. I am experienced in Android development and use VSCode as my primary IDE. I'm comfortable working across multiple operating systems and rely on GitHub, Trello, Figma, and Draw.io for project management and design. Additionally, I produce music with FL Studio. </p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={arduino} alt="Image" />
                                <h5> Aurdino </h5>
                            </div>
                            <div className="item">
                                <img src={cpp} alt="Image" />
                                <h5> C++ </h5>
                            </div>
                            <div className="item">
                                <img src={raspberrypi} alt="Image" />
                                <h5> Raspberry PI </h5>
                            </div>
                            <div className="item">
                                <img src={python} alt="Image" />
                                <h5> Python </h5>
                            </div>
                            <div className="item">
                                <img src={react} alt="Image" />
                                <h5> React JS</h5>
                            </div>
                            <div className="item">
                                <img src={selenium} alt="Image" />
                                <h5> Selenium </h5>
                            </div>
                            <div className="item">
                                <img src={figma} alt="Image" />
                                <h5> Figma </h5>
                            </div>
                            <div className="item">
                                <img src={github} alt="Image" />
                                <h5> GitHub </h5>
                            </div>
                            <div className="item">
                                <img src={flutter} alt="Image" />
                                <h5> Flutter </h5>
                            </div>
                            <div className="item">
                                <img src={android} alt="Image" />
                                <h5> Android </h5>
                            </div>
                            <div className="item">
                                <img src={tensorflow} alt="Image" />
                                <h5> TensorFlow </h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
  )
}
